import {useState, useEffect} from 'react';
import {getScrollTop} from '../helpers/getScrollTop';

/**
 * Get the scrollTop of the page and return it
 * as the user scrolls down the page.
 *
 * @return {number} The scrollTop value.
 */
export const useClientScroll = () => {
  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    const onScroll = (e) => {
      const scrollTop = getScrollTop();
      setScrollTop(scrollTop);
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);
  return scrollTop;
};
