import PropTypes from 'prop-types';
import 'styled-components/macro';
import styled, {css} from 'styled-components';
import {mobileBreakpoint} from '../../helpers/breakpoints';
import IconButtonGoBack from '../../images/button-go-back.png';
import Loader from '../../images/loader.svg';

// We have to export the css for the
//  Share page for use with social buttons.
export const buttonCSS = css`
  cursor: pointer;
  color: var(--color-btn-primary-text);
  background: var(--color-btn-primary-default);
  text-align: center;
  height: 2.625rem;
  border-radius: 0.375rem;
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: 'Google Sans';
  transition: var(--transition-all);
  width: ${(props) => props.width ? props.width : '9rem'};
  &:hover {
    background: var(--color-btn-primary-hover-focus);
  }
  &:focus {
    outline: 0;
    border: 2px solid var(--color-white);
    background: var(--color-btn-primary-hover-focus);
  }
  &:active {
    border: none;
    background: var(--color-btn-primary-active);
  }
  &:disabled {
    cursor: pointer;
    pointer-events: none;
    color: var(--color-btn-all-disabled-text);
    background: var(--color-btn-all-disabled);
  }

  ${(props) => props.loading && css`
    color: transparent;
    pointer-events: none;
    background-image: url(${Loader});
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: center center;
    transition: none;
    pointer-events: none;
    &:hover,
    &:focus,
    &:active,
    &:disabled {
      color: transparent;
      background-image: url(${Loader});
      background-image: url(${Loader});
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: center center;
      transition: none;
    }
  `};
`;

export const Button = styled.button`
  ${buttonCSS};
`;

export const buttonXLCSS = css`
  height: 3.5rem;
  width: ${(props) => props.width ? props.width : '14.25rem;'};
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 5rem;
  border-radius: 0.5rem;
  letter-spacing: 0.01875rem;
  &:disabled {
    color: var(--color-btn-all-disabled-text);
    background: var(--color-btn-all-disabled);
  }
`;

export const ButtonXL = styled(Button)`
  ${buttonXLCSS};
`;

const buttonTextCSS = css`
  cursor: pointer;
  color: var(--color-white);
  background: rgba(255, 255, 255, 0.04);
  width: 5.6875rem;
  &:hover {
    background: rgba(255, 255, 255, 0.08);
  }
  &:focus {
    border: 2px solid var(--color-white);
    background: rgba(255, 255, 255, 0.08);
  }
  &:active {
    border: none;
    background: rgba(255, 255, 255, 0.12);
  }
  &:disabled {
    color: rgba(255, 255, 255, 0.32);
    background: rgba(255, 255, 255, 0.04);
  }
`;

export const ButtonText = styled(Button)`
  ${buttonTextCSS};
`;

export const buttonIconCSS = css`
  ${buttonTextCSS};
  height: 2.625rem;
  width: 2.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.04);
`;

export const ButtonIcon = styled(ButtonText)`
  ${buttonIconCSS};
`;

export const ButtonGoBack = styled(Button)`
  ${buttonIconCSS};
  width: 3rem!important;
  background-image: url(${IconButtonGoBack});
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 1rem;
  transition: none;
  &:hover {
    background-image: url(${IconButtonGoBack});
    background-repeat: no-repeat;
    background-position: center center;
  }
  &:focus {
    background-image: url(${IconButtonGoBack});
    background-repeat: no-repeat;
    background-position: center center;
  }
  &:active {
    background-image: url(${IconButtonGoBack});
    background-repeat: no-repeat;
    background-position: center center;
  }
`;

// Restyled any buttons we put in here
// to correctly display on mobile.
export const ButtonMobileContainer = styled.div`
  display: none;
  position: fixed;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  padding: 1rem;
  background: var(--color-main-background);
  box-shadow: 0 0 14px 4px var(--color-main-background);
  z-index: 1000;
  @media (max-width: ${mobileBreakpoint}) {
    display: flex;
  }
  button {
    width: 100%;
  }
`;

Button.propTypes = {
  width: PropTypes.string,
  loading: PropTypes.bool,
};
