import React from 'react';
import PropTypes from 'prop-types';
import 'styled-components/macro';
import {css} from 'styled-components';
import FirebaseHorizontalLockup from
  '../images/dear-firebase-horizontal-lockup.svg';
import {mobileBreakpoint} from '../helpers/breakpoints';
import {actions, steps} from './appReducer';
import {Button, ButtonText} from './UI/Button';

/**
 * The global header, shown on all pages.
 *
 * @param {{ dispatch: function,
 *     state: object,
 *     disabledSubmitButton: boolean,
 *     withSubmitButton: boolean,
 *     handleSubmit: function,
 *     loading: boolean
 *   }} props
 *     dispatch: dispatches a useReducer action.
 *     state: applications global state.
 *     disabledSubmitButton: disables submit button.
 *     withSubmitButton: header shows Submit button if true
 *     handleSubmit: submits the postcard data to the server.
 *     loading: is true when waiting on API response.
 * @return {component}
 */
export const Header = ({
  disabledSubmitButton,
  withSubmitButton = false,
  dispatch,
  handleSubmit,
  loading,
  state,
}) => {
  const handleShowModal = () => {
    if (state && state.step === steps.SHARE) {
      dispatch({
        type: actions.CHANGE_STEP,
        payload: steps.START,
      });
    } else {
      dispatch({
        type: actions.SHOW_MODAL,
        payload: true,
      });
    }
  };

  return (
    <div css={css`
      display: flex;
      justify-content: ${withSubmitButton ? 'space-between' : 'center'};
      margin-top: 2.97rem;
      padding: ${withSubmitButton ? '0 1.8rem' :'0'};
      @media (max-width: ${mobileBreakpoint}) {
        justify-content: center;
        margin-top: 1.3rem;
      }
    `}>
      <div
        css={css`
          display: flex;
          cursor: pointer;
        `}
        onClick={handleShowModal}
      >
        <img
          src={FirebaseHorizontalLockup} css={css`
            height: 32px;
            margin-top: 8px;
            @media (max-width: ${mobileBreakpoint}) {
              margin-top: 0;
              height: 28px;
            }
          `}
          alt="Firebase Logo"
        />
      </div>
      {withSubmitButton &&
        <div css={css`
          @media (max-width: ${mobileBreakpoint}) {
            display: none;
          }
        `}>
          <ButtonText
            css={css`margin-right: 1.5rem;`}
            onClick={handleShowModal}
            disabled={loading}
          >
            Start over
          </ButtonText>
          <Button
            onClick={handleSubmit}
            disabled={disabledSubmitButton}
            loading={loading}
          >
            Submit
          </Button>
        </div>
      }
    </div>
  );
};

Header.propTypes = {
  disabledSubmitButton: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  withSubmitButton: PropTypes.bool,
  loading: PropTypes.bool,
  state: PropTypes.object,
};
