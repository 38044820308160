import React, {useState} from 'react';
import PropTypes from 'prop-types';
import 'styled-components/macro';
import styled, {css} from 'styled-components';
import {stickerNames} from './constants';
import {mobileBreakpoint, cardBreakpoint} from '../../helpers/breakpoints';
import {Sticker} from './Sticker';

// Static
import Sparky from '../../images/stickers/sparky.png';
import Please from '../../images/stickers/please.png';
import Fire from '../../images/stickers/fire.png';
import Rocket from '../../images/stickers/rocket.png';
import Heart from '../../images/stickers/heart.png';
import Happy from '../../images/stickers/happy.png';
import Sob from '../../images/stickers/sob.png';
import Clap from '../../images/stickers/clap.png';
import Ladybug from '../../images/stickers/ladybug.png';
import Coffee from '../../images/stickers/coffee.png';
import PineappleSparky from '../../images/stickers/pineapple.png';
import Games from '../../images/stickers/games.png';
import Check from '../../images/stickers/check.png';
import Performance from '../../images/stickers/performance.png';
import Bug from '../../images/stickers/bug.png';

// Gifs
import SparkyGif from '../../images/stickers/sparky.gif';
import PleaseGif from '../../images/stickers/please.gif';
import FireGif from '../../images/stickers/fire.gif';
import RocketGif from '../../images/stickers/rocket.gif';
import HeartGif from '../../images/stickers/heart.gif';
import HappyGif from '../../images/stickers/happy.gif';
import SobGif from '../../images/stickers/sob.gif';
import ClapGif from '../../images/stickers/clap.gif';
import LadybugGif from '../../images/stickers/ladybug.gif';
import CoffeeGif from '../../images/stickers/coffee.gif';
import PineappleSparkyGif from '../../images/stickers/pineapple.gif';
import GamesGif from '../../images/stickers/games.gif';
import CheckGif from '../../images/stickers/check.gif';
import PerformanceGif from '../../images/stickers/performance.gif';
import BugGif from '../../images/stickers/bug.gif';

/**
 * The dock below the Card where the stickers live.
 *
 * A user can drag a sticker away from here onto the card.
 * When the user starts dragging a sticker the dock turns into
 * a 'trash' location the user can drop the sticker back onto.
 * The sticker will return back to its original location if the
 * user drops a sticker off the Card, or back onto this sticker dock.
 *
 * @param {{ cardRect: object,
 *     stickerLocationRef: object,
 *     showCardTooltip: boolean,
 *     setShowCardTooltip: function,
 *   }} props
 *     cardRect: The dimensions and X/Y coordinates of the Card.
 *     stickerLocationRef: The X/Y coordinates of each sticker on the Card.
 *     showCardTooltip: If card tooltip is being displayed.
 *     setIsDraggingStickerName: Set the card tooltip display state.
 * @return {component}
 */
export const StickerDock = ({
  cardRect,
  stickerLocationRef,
  showCardTooltip,
  setShowCardTooltip,
}) => {
  // `isDraggingStickerName` is set to the name of the Sticker we are
  // dragging so we can set the z-index of it to overlap the 'trash'
  // state of the dock, while other stickers are hidden below.
  const [isDraggingStickerName, setIsDraggingStickerName] = useState(null);
  return (
    <DockContainer>
      <DockTrash showTrash={isDraggingStickerName !== null}>
        {isDraggingStickerName !== null &&
          'Drop here to remove'}
      </DockTrash>

      {stickerNames.map((name) => {
        const {staticImg, animatedGif} = getStickerImages({name});
        return (
          <Sticker
            key={name}
            cardRect={cardRect}
            emojiStatic={staticImg}
            emojiAnimated={animatedGif}
            stickerLocationRef={stickerLocationRef}
            stickerName={name}
            isDraggingSticker={isDraggingStickerName}
            setIsDraggingSticker={setIsDraggingStickerName}
            showCardTooltip={showCardTooltip}
            setShowCardTooltip={setShowCardTooltip}
          />
        );
      })}
    </DockContainer>
  );
};

StickerDock.propTypes = {
  cardRef: PropTypes.object,
  stickerLocationRef: PropTypes.object.isRequired,
  showCardTooltip: PropTypes.bool.isRequired,
  setShowCardTooltip: PropTypes.func.isRequired,
};

const shared = css`
  display: flex;
  align-items: center;
  height: 4.5rem;
  width: 100%;
  max-width: 62rem;
  border-radius: 10.5rem;

  @media (max-width: ${mobileBreakpoint}) {
    width: 20rem;
    height: 11rem;
    margin: 0 auto;
    border-radius: 1.5rem;
    flex-wrap: wrap;
  }
`;

const responsiveDock = css`
  max-width: calc(100% - 1.5rem);
`;

const DockContainer = styled.div`
  position: relative;
  justify-content: space-around;
  margin: 2rem auto;
  padding: 0 1rem;
  @media (max-width: ${cardBreakpoint}) {
    ${responsiveDock};
  }
  @media (max-width: ${mobileBreakpoint}) {
    // Targets the stickers
    div:not(:first-child) {
      margin: 0 0.0625rem;
    }
  }
  ${shared};
`;

const DockTrash = styled.div`
  ${shared};
  position: absolute;
  justify-content: center;
  z-index: ${(props) => props.showTrash ? 2 : 0};
  background: ${(props) => props.showTrash ?
    '#508BDD;' : 'var(--color-background-faded)'};
  @media (max-width: ${cardBreakpoint}) {
    ${responsiveDock};
  }
`;

const getStickerImages = ({name}) => {
  switch (name) {
    case stickerNames[0]:
      return {
        staticImg: Sparky,
        animatedGif: SparkyGif,
      };
    case stickerNames[1]:
      return {
        staticImg: Please,
        animatedGif: PleaseGif,
      };
    case stickerNames[2]:
      return {
        staticImg: Fire,
        animatedGif: FireGif,
      };
    case stickerNames[3]:
      return {
        staticImg: Rocket,
        animatedGif: RocketGif,
      };
    case stickerNames[4]:
      return {
        staticImg: Heart,
        animatedGif: HeartGif,
      };
    case stickerNames[5]:
      return {
        staticImg: Happy,
        animatedGif: HappyGif,
      };
    case stickerNames[6]:
      return {
        staticImg: Sob,
        animatedGif: SobGif,
      };
    case stickerNames[7]:
      return {
        staticImg: Clap,
        animatedGif: ClapGif,
      };
    case stickerNames[8]:
      return {
        staticImg: Ladybug,
        animatedGif: LadybugGif,
      };
    case stickerNames[9]:
      return {
        staticImg: Coffee,
        animatedGif: CoffeeGif,
      };
    case stickerNames[10]:
      return {
        staticImg: PineappleSparky,
        animatedGif: PineappleSparkyGif,
      };
    case stickerNames[11]:
      return {
        staticImg: Games,
        animatedGif: GamesGif,
      };
    case stickerNames[12]:
      return {
        staticImg: Check,
        animatedGif: CheckGif,
      };
    case stickerNames[13]:
      return {
        staticImg: Performance,
        animatedGif: PerformanceGif,
      };
    case stickerNames[14]:
      return {
        staticImg: Bug,
        animatedGif: BugGif,
      };
    default:
      return null;
  }
};
