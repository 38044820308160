import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import styled from 'styled-components';
import {actions} from '../appReducer';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

/**
 * Error Snackbar.
 *
 * Will show a error message if the postcard submission fails.
 *
 * @param {{showError: bool, dispatch: function}} props
 *     showError: if true, show error. if false, hide error.
 *     dispatch: dispatches a useReducer action.
 * @return {component}
 */
export const DisplayError = ({showError, dispatch}) => {
  const handleResetError = () => {
    dispatch({
      type: actions.SHOW_ERROR,
      payload: false,
    });
  };

  return (
    <div>
      <Snackbar
        open={showError}
        autoHideDuration={3000}
        onClose={handleResetError}
      >
        <Error severity="error">
          Something went wrong. Please try again.
        </Error>
      </Snackbar>
    </div>
  );
};

DisplayError.propTypes = {
  showError: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const Error = styled(Alert)`
  // Need important to override material-ui :/
  background: #D32F2F!important;
  background-color: #D32F2F!important;
  .MuiAlert-message {
    background: #D32F2F;
  }
`;
