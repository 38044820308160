import React from 'react';
import PropTypes from 'prop-types';
import 'styled-components/macro';
import styled from 'styled-components';

/**
 * The animated sticker image.
 *
 * @param {{name: string, animationPath: string}} props
 *     name: name of the sticker.
 *     animationPath: path to animated sticker gif.
 * @return {component}
 */
export const AnimatedSticker = ({name, animationPath}) => {
  return (
    <Gif background={animationPath} alt={`${name} sticker`} />
  );
};

AnimatedSticker.propTypes = {
  name: PropTypes.string.isRequired,
  animationPath: PropTypes.string.isRequired,
};

const Gif = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-size: contain;
  background-image: ${(props) => `url(${props.background})`};
  // Fix for blurry image in safari due to using transform scale()
  transform: translateZ(0);
`;
