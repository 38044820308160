/**
 * Each page that can be navigated to.
 * @enum {string}
 */
export const steps = {
  START: 'START',
  THEME: 'THEME',
  CARD: 'CARD',
  SHARE: 'SHARE',
};

/**
 * The card themes a user can select
 * @enum {string}
 */
export const themes = {
  MINIMAL: 'MINIMAL',
  FOUR_K: 'FOUR_K',
  COLORFUL: 'COLORFUL',
  TRADITIONAL: 'TRADITIONAL',
};

/**
 * Actions that are dispatched by apps useReducer
 * @enum {string}
 */
export const actions = {
  CHANGE_STEP: 'CHANGE_STEP',
  SELECT_THEME: 'SELECT_THEME',
  SET_PUBLIC_URL: 'SET_PUBLIC_URL',
  SHOW_MODAL: 'SHOW_MODAL',
  SHOW_ERROR: 'SHOW_ERROR',
};

/** @const {object} initialState The initial state for apps useReducer. */
export const initialState = {
  step: steps.START,
  theme: null,
  cardPagePublicUrl: null,
  cardImagePublicUrl: null,
  showModal: false,
  showError: false,
  localImage: null,
};

/**
 * Global state for the application.
 *
 * @param {object} state The apps global state.
 * @param {object} action The dispatch action used to change state.
 * @return {object} the altered state.
 */
export const appReducer = (state, action) => {
  switch (action.type) {
    case actions.CHANGE_STEP:
      if (action.payload === steps.START) {
        // Reset all state on return to start
        return {
          ...state,
          ...initialState,
        };
      }
      if (action.payload?.step === steps.SHARE) {
        return {
          ...state,
          step: action.payload.step,
          localImage: action.payload.localImage,
        };
      }
      return {...state, step: action.payload};
    case actions.SELECT_THEME:
      return {...state, theme: action.payload};
    case actions.SET_PUBLIC_URL:
      return {...state,
        cardPagePublicUrl: action.payload.cardPagePublicUrl,
        cardImagePublicUrl: action.payload.cardImagePublicUrl,
      };
    case actions.SHOW_MODAL:
      return {...state, showModal: action.payload};
    case actions.SHOW_ERROR:
      return {...state, showError: action.payload};
    default:
      return {...state};
  }
};
