import React, {useEffect, useReducer} from 'react';
import 'styled-components/macro';
import styled from 'styled-components';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import background from '../images/main-background.svg';
import {initialState, appReducer, steps} from './appReducer';
import {PreloadImages} from './PreloadImages';
import {StartPage} from './StartPage';
import {ChooseThemePage} from './ChooseThemePage';
import {CardPage} from './CardPage/index';
import {SharePage} from './SharePage';
import {Modal} from './UI/Modal';
import {DisplayError} from './UI/DisplayError';

/**
 * The main entry point to the React apps logic.
 *
 * This encapsulates all page navigation logic, and contains the two
 * global components that can be dispatched / shown from anywhere, which are
 * the Modal and DisplayError.
 * @return {component}
 */
export const PageContainer = () => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  useEffect(() => {
    // If the user refreshes the page and they are scrolled down some
    // the browser keeps the scroll position but the
    // calculations for the dropzone bounds get wonky.
    // `beforeunload` doesn't seem to work in safari but the wonky
    // behavour doesn't happen either, so thats lucky.
    window.addEventListener('beforeunload', (e) => {
      window.scrollTo(0, 0);
      delete e['returnValue'];
    });
  }, []);

  return (
    <>
      <PreloadImages />
      <Wrapper>
        <InnerContainer>
          <TransitionGroup className="transition-group">
            <CSSTransition
              key={state.step}
              timeout={{enter: 300, exit: 300}}
              classNames={'fade'}
            >
              <div className="route-section">
                { state.step === steps.START &&
                    <StartPage dispatch={dispatch} />
                }
                { state.step === steps.THEME &&
                    <ChooseThemePage state={state} dispatch={dispatch} />
                }
                { state.step === steps.CARD &&
                    <>
                      <CardPage state={state} dispatch={dispatch} />
                    </>
                }
                { state.step === steps.SHARE &&
                    <SharePage state={state} dispatch={dispatch} />
                }
              </div>
            </CSSTransition>
          </TransitionGroup>
        </InnerContainer>
      </Wrapper>
      <DisplayError showError={state.showError} dispatch={dispatch} />
      <Modal state={state} dispatch={dispatch} />
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: contain;
  background-color: var(--color-main-background);

  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
`;

const InnerContainer = styled.div`
  max-width: 74.5rem;
  width: 100%;
  padding-bottom: 2rem;

  div.transition-group {
    position: relative;
  }

  div.route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
`;
