import React, {useState, useRef, useEffect, createRef} from 'react';
import PropTypes from 'prop-types';
import 'styled-components/macro';
import styled, {css} from 'styled-components';
import {
  TextField as MaterialUiTextField,
  Tooltip as MaterialUiTooltip,
} from '@material-ui/core';
import TextareaAutosize from 'react-textarea-autosize';
import ReCAPTCHA from 'react-google-recaptcha';

import {mobileBreakpoint, cardBreakpoint} from '../../helpers/breakpoints';
import {useWindowSize} from '../../hooks/useWindowResize';
import {useClientRect} from '../../hooks/useClientRect';
import {useClientScroll} from '../../hooks/useClientScroll';
import {stickerNames} from './constants';
import {actions, themes} from '../appReducer';
import {submitCard} from './submit-card';

import {Header} from '../Header';
import {Button, ButtonMobileContainer, ButtonGoBack} from '../UI/Button';
import {StickerDock} from './StickerDock';
import {PrintStickersToDOM} from './PrintStickersToDOM';

import MinimalCard from '../../images/theme-minimal-card.svg';
import FourKCard from '../../images/theme-4k-card.svg';
import ColorfulCard from '../../images/theme-colorful-card.svg';
import TraditionalCard from '../../images/theme-traditional-card.svg';

import MinimalCardNoTargets from
  '../../images/theme-minimal-no-drop-targets.svg';
import FourKCardNoTargets from '../../images/theme-4k-no-drop-targets.svg';
import ColorfulCardNoTargets from
  '../../images/theme-colorful-no-drop-targets.svg';
import TraditionalCardNoTargets
  from '../../images/theme-traditional-no-drop-targets.svg';

/**
 * The postcard creation page.
 *
 * Allows user to type a message onto the postcard,
 * drag stickers onto it, and submit the postcard to the server.
 *
 * @param {{dispatch: function, state: object}} props
 *     dispatch: dispatches a useReducer action.
 *     state: applications global state.
 * @return {component}
 */
export const CardPage = ({state, dispatch}) => {
  const size = useWindowSize();
  const [forceUpdate, setForceUpdate] = React.useState(true);
  // We need to force the cardRect bounds to be re-calculated
  // if the page is resized or, on mobile view, if the input
  // fields size changes, which pushes the Card down the page.
  // We also need to do this on scroll for small screen (mobile).
  const [cardRect, cardRef] = useClientRect(forceUpdate);
  const stickerLocationRef = useRef(stickerNamesRefObject());
  const loveMessageRef = useRef(null);
  const wishMessageRef = useRef(null);
  const recaptchaRef = createRef();

  const [loading, setLoading] = useState(false);
  const [iLoveThatMessage, setILoveThatMessage] = useState('');
  const [iWishThatMessage, setIWishThatMessage] = useState('');
  const [showLoveTextCount, setShowLoveTextCount] = useState(false);
  const [showWishTextCount, setShowWishTextCount] = useState(false);
  const [printStickersToDOM, setPrintStickersToDOM] = useState(false);
  const [showCardTooltip, setShowCardTooltip] = React.useState(true);

  const scrollTop = useClientScroll();
  useEffect(() => {
    setForceUpdate((state) => !state);
  }, [size, scrollTop]);

  let background = MinimalCard;
  let noTargetsBackground = MinimalCardNoTargets;
  let tooltipLocation = {top: '286px', left: '738px'};
  let tooltipLocationMobile = {top: '86px', left: '203px'};
  let textareaWidth = '32rem';
  let mobileTextareaWidth = '9.125rem';
  let themeFontFamily = 'Google Sans';
  let textIndent = '9.3rem';
  let mobileTextIndent = '3.2rem';
  let textIndentWishThat = '9.625rem';
  let mobileTextIndentWishThat = '3.3rem';
  if (state.theme === themes.FOUR_K) {
    background = FourKCard;
    noTargetsBackground = FourKCardNoTargets;
    textIndent = '13.4rem';
    mobileTextIndent = '4.4rem';
    textIndentWishThat = '13.5rem';
    mobileTextIndentWishThat = '4.5rem';
    themeFontFamily = 'Roboto Mono';
    tooltipLocation = {top: '240px', left: '995px'};
    tooltipLocationMobile = {top: '72px', left: '276px'};
    textareaWidth = '45rem';
    mobileTextareaWidth = '12.725rem';
  } else if (state.theme === themes.COLORFUL) {
    background = ColorfulCard;
    noTargetsBackground = ColorfulCardNoTargets;
    textIndent = '8.7rem';
    mobileTextIndent = '2.86rem';
    textIndentWishThat = '9rem';
    mobileTextIndentWishThat = '2.96rem';
    themeFontFamily = 'Coda';
    tooltipLocation = {top: '232px', left: '821px'};
    tooltipLocationMobile = {top: '74px', left: '226px'};
  } else if (state.theme === themes.TRADITIONAL) {
    background = TraditionalCard;
    noTargetsBackground = TraditionalCardNoTargets;
    textIndent = '9.8rem';
    mobileTextIndent = '3.24rem';
    textIndentWishThat = '9.9rem';
    mobileTextIndentWishThat = '3.26rem';
    themeFontFamily = 'Lacquer';
    tooltipLocation = {top: '386px', left: '783px'};
    tooltipLocationMobile = {top: '118px', left: '217px'};
  }

  const handleShowModal = (e) => {
    e.preventDefault();
    dispatch({
      type: actions.SHOW_MODAL,
      payload: true,
    });
  };

  const handleLoveThatMessage = (e) => {
    const value = e.target.value;
    if (value.length >= inputMessageLimit) {
      setILoveThatMessage(value.substring(0, inputMessageLimit));
    } else {
      setILoveThatMessage(value);
    }
  };

  const handleWishThatMessage = (e) => {
    const value = e.target.value;
    if (value.length >= inputMessageLimit) {
      setIWishThatMessage(value.substring(0, inputMessageLimit));
    } else {
      setIWishThatMessage(value);
    }
  };

  const handleMobileLoveInputBlur = () => {
    setShowLoveTextCount(false);
    setForceUpdate((state) => !state);
  };

  const handleMobileWishInputBlur = () => {
    setShowWishTextCount(false);
    setForceUpdate((state) => !state);
  };

  const handleSubmit = (e) => {
    e.preventDefault(e);
    setLoading(true);
    setPrintStickersToDOM(true);
    try {
      recaptchaRef.current.executeAsync()
          .then((captchaToken) => {
            submitCard({
              dispatch,
              setPrintStickersToDOM,
              stickerLocations: stickerLocationRef ?
                stickerLocationRef.current : null,
              iLoveThatMessage,
              iWishThatMessage,
              setLoading,
              captchaToken,
            });
          })
          .catch((error) => {
            setLoading(false);
            setPrintStickersToDOM(false);
            dispatch({
              type: actions.SHOW_ERROR,
              payload: true,
            });
            console.error('Captcha error', error);
          });
    } catch (error) {
      setLoading(false);
      setPrintStickersToDOM(false);
      dispatch({
        type: actions.SHOW_ERROR,
        payload: true,
      });
      console.error('Issue calling recaptcha:', error);
    }
  };

  const onMobile = matchMedia(`(max-width: ${mobileBreakpoint})`);
  const disabledSubmitButton = iLoveThatMessage.length === 0 &&
    iWishThatMessage.length === 0;
  return (
    <div css={css`
      // Fix issue where safari highlights text on
      // page while dragging stickers.
      user-select: none;
      @media (max-width: ${cardBreakpoint}) {
        // Give page more scroll height so on mobile
        // the submit button doesn't overlap the StickerDock
        margin-bottom: 6rem;
      }
      @media (max-width: ${mobileBreakpoint}) {
        // Hide captcha on mobile
        .grecaptcha-badge {
          visibility: hidden;
        }
      }
    `}>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_CAPTCHA_KEY}
        ref={recaptchaRef}
        size="invisible"
      />
      <Header
        dispatch={dispatch}
        disabledSubmitButton={disabledSubmitButton}
        withSubmitButton={true}
        handleSubmit={handleSubmit}
        loading={loading}
      />
      <MobileTextFieldContainer>
        <div>
          <MobileTextField
            label="I love that you..."
            placeholder="start typing here"
            multiline
            variant="filled"
            value={iLoveThatMessage}
            onChange={(e) => handleLoveThatMessage(e)}
            onFocus={() => setShowLoveTextCount(true)}
            onBlur={handleMobileLoveInputBlur}
            helperText={
              <HelperText
                errorState={iLoveThatMessage.length >= showErrorStateAt}
              >
                {iLoveThatMessage.length} / {inputMessageLimit}
              </HelperText>
            }
          />
        </div>
        <div css={css`margin-top: 1rem;`}>
          <MobileTextField
            label="I wish that you..."
            placeholder="start typing here"
            multiline
            variant="filled"
            value={iWishThatMessage}
            onChange={(e) => handleWishThatMessage(e)}
            onFocus={() => setShowWishTextCount(true)}
            onBlur={handleMobileWishInputBlur}
            helperText={
              <HelperText
                errorState={iWishThatMessage.length >= showErrorStateAt}
              >
                {iWishThatMessage.length} / {inputMessageLimit}
              </HelperText>
            }
          />
        </div>
      </MobileTextFieldContainer>
      <Card
        ref={cardRef}
        background={background}
        noTargetsBackground={noTargetsBackground}
        $notSnapshotCard={true}
      >
        <Tooltip
          title="Drop a sticker"
          placement="top"
          arrow
          PopperProps={{
            disablePortal: true,
          }}
          open={showCardTooltip}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <TooltipLocator
            tooltipLocation={tooltipLocation}
            tooltipLocationMobile={tooltipLocationMobile}
          />
        </Tooltip>

        <Message
          onClick={() => loveMessageRef.current.focus()}
          $fadeColor={showWishTextCount}
          $notSnapshotCard={true}
          $themeFontFamily={themeFontFamily}
        >
            I love that you...
        </Message>
        <Textarea
          ref={loveMessageRef}
          value={iLoveThatMessage}
          onChange={(e) => handleLoveThatMessage(e)}
          onFocus={() => setShowLoveTextCount(true)}
          onBlur={() => setShowLoveTextCount(false)}
          placeholder="start typing here"
          disabled={onMobile.matches}
          $fadeColor={showWishTextCount}
          $notSnapshotCard={true}
          $textareaWidth={textareaWidth}
          $mobileTextareaWidth={mobileTextareaWidth}
          $themeFontFamily={themeFontFamily}
          $textIndent={textIndent}
          $mobileTextIndent={mobileTextIndent}
          minRows={4}
        >
        </Textarea>
        <DesktopMessaseLengthCount
          show={showLoveTextCount}
          errorState={iLoveThatMessage.length >= showErrorStateAt}
          css={css`
            margin-bottom: 1.9rem;
          `}
        >
          { iLoveThatMessage.length } / <span>{inputMessageLimit}</span>
        </DesktopMessaseLengthCount>
        <Message
          onClick={() => wishMessageRef.current.focus()}
          $fadeColor={showLoveTextCount}
          $notSnapshotCard={true}
          $themeFontFamily={themeFontFamily}
        >
          I wish that you...
        </Message>
        <Textarea
          ref={wishMessageRef}
          value={iWishThatMessage}
          onChange={(e) => handleWishThatMessage(e)}
          onFocus={() => setShowWishTextCount(true)}
          onBlur={() => setShowWishTextCount(false)}
          placeholder="start typing here"
          disabled={onMobile.matches}
          $fadeColor={showLoveTextCount}
          $notSnapshotCard={true}
          $textareaWidth={textareaWidth}
          $mobileTextareaWidth={mobileTextareaWidth}
          $themeFontFamily={themeFontFamily}
          $textIndent={textIndent}
          $mobileTextIndent={mobileTextIndent}
          minRows={4}
          css={css`
            text-indent: ${textIndentWishThat};
            @media (max-width: ${mobileBreakpoint}) {
              text-indent: ${mobileTextIndentWishThat};
            }
          `}
        >
        </Textarea>
        <DesktopMessaseLengthCount
          show={showWishTextCount}
          errorState={iWishThatMessage.length >= showErrorStateAt}
        >
          { iWishThatMessage.length } / <span>{inputMessageLimit}</span>
        </DesktopMessaseLengthCount>
      </Card>

      <StickerDock
        cardRect={cardRect}
        stickerLocationRef={stickerLocationRef}
        showCardTooltip={showCardTooltip}
        setShowCardTooltip={setShowCardTooltip}
      />
      <CaptchaLegal>
        This site is protected by reCAPTCHA and the Google&nbsp;
        <a href="https://policies.google.com/privacy">Privacy Policy</a>&nbsp;
        and <a href="https://policies.google.com/terms">Terms of Service</a>&nbsp;
        apply.
      </CaptchaLegal>

      <ButtonMobileContainer>
        <ButtonGoBack onClick={handleShowModal}>&nbsp;</ButtonGoBack>
        <Button
          disabled={disabledSubmitButton}
          loading={loading}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </ButtonMobileContainer>

      <div css={css`
        position: relative;
        height: 0;
        overflow: hidden;
      `}>
        <div id="card" css={css`padding-bottom: 5rem;`}>
          <CardForSnapshot background={noTargetsBackground}>
            <Message $fadeColor={false} $themeFontFamily={themeFontFamily}>
                I love that you...
            </Message>
            <Textarea
              value={iLoveThatMessage}
              placeholder=""
              disabled={true}
              $fadeColor={false}
              $textareaWidth={textareaWidth}
              $themeFontFamily={themeFontFamily}
              $textIndent={textIndent}
              css={css`
                margin-bottom: 2.7125rem;
              `}
            >
            </Textarea>
            <Message $fadeColor={false} $themeFontFamily={themeFontFamily}>
              I wish that you...
            </Message>
            <Textarea
              value={iWishThatMessage}
              placeholder=""
              disabled={true}
              $fadeColor={false}
              $textareaWidth={textareaWidth}
              $themeFontFamily={themeFontFamily}
              $textIndent={textIndentWishThat}
              css={css`
                text-indent: ${textIndentWishThat};
              `}
            >
            </Textarea>
            { printStickersToDOM &&
                <PrintStickersToDOM
                  cardRect={cardRect}
                  onMobile={onMobile.matches}
                  stickerLocationRef={stickerLocationRef}
                />}
          </CardForSnapshot>
        </div>
      </div>
    </div>
  );
};

CardPage.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const inputMessageLimit = 170;
const showErrorStateAt = 163;

const mobileWidth = css`
  margin: 1.25rem auto;
  max-width: 20rem;
`;

const Card = styled.div`
  position: relative;
  margin: 2.5rem auto 0 auto;
  height: 40rem;
  width: 100%;
  max-width: 71rem;
  border-radius: 0.875rem;
  background-image: ${(props) => `url(${props.background})`};
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 5.4rem;
  padding-left: 5.25rem;
  ${(props) => props.$notSnapshotCard && css`
    @media (max-width: ${cardBreakpoint}) {
      background-image: ${(props) => `url(${props.noTargetsBackground})`};
      max-width: calc(100% - 2.5rem);
      margin: 1.25rem;
    }
    @media (max-width: ${mobileBreakpoint}) {
      ${mobileWidth};
      height: 11.25rem;
      padding-top: 1.4375rem;
      padding-left: 1.4375rem;
      border-radius: 0.25rem;
      background-image: ${(props) => `url(${props.background})`};
    }
  `};
`;

const CardForSnapshot = styled(Card)`
  width: 71rem;
  // cut off stickers that are over the egde of the card
  overflow: hidden;
`;

const messageText = css`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2.2rem;
  color: var(--color-card-text);
  transition: var(--transition-all);
  opacity: ${({$fadeColor}) => $fadeColor ? 0.5 : 1};
  ${({$notSnapshotCard}) => $notSnapshotCard && css`
    @media (max-width: ${mobileBreakpoint}) {
      font-size: 0.4225rem;
      line-height: 0.6rem;
    }
  `};
`;

const Textarea = styled(TextareaAutosize)`
  ${messageText};
  font-family: ${(props) => props.$themeFontFamily};
  outline: none;
  border: 0;
  width: ${(props) => props.$textareaWidth};
  min-height: 9rem;
  text-indent: ${(props) => props.$textIndent};
  background: transparent;
  margin-top: -2.3rem;
  resize: none;
  ${(props) => props.$notSnapshotCard && css`
    @media (max-width: ${mobileBreakpoint}) {
      min-height: 3.7rem;
      width: ${(props) => props.$mobileTextareaWidth};
      text-indent: ${(props) => props.$mobileTextIndent};
      margin-top: -0.7rem;
    }
  `};
`;

const Message = styled.div`
  ${messageText};
  font-family: ${(props) => props.$themeFontFamily};
`;

const DesktopMessaseLengthCount = styled.div`
  color: ${(props) => props.errorState ?
    'var(--color-card-error-dark)' : 'var(--color-card-length-message)'};
  font-size: 0.625rem;
  font-weight: 500;
  span {
    color: ${(props) => props.errorState ?
      'var(--color-card-error-light)' : 'var(--color-card-length-message)'};
    font-weight: 400;
  }
  transition: all .3s ease-in;
  visibility: hidden;
  opacity: 0;
  ${(props) => props.show && css`
    visibility: visible;
    opacity: 1;
  `};
  @media (max-width: ${mobileBreakpoint}) {
    display: none;
  }
`;

const Tooltip = styled((props) => (
  <MaterialUiTooltip
    classes={{popper: props.className, tooltip: 'tooltip'}}
    {...props}
  />
))`
  & .tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    background-color: #AECBFA;
    font-family: 'Google Sans';
    color: #202124;
    font-weight: 500;
    font-size: 0.75rem;
    width: 6.625rem;
    height: 2rem;
    letter-spacing: 0.01875rem;
    span {
      color: #AECBFA;
    }
  }

  @media (max-width: ${cardBreakpoint}) and (min-width: ${mobileBreakpoint}) {
    & .tooltip {
      display: none;
    }
  }

  @media (max-width: ${mobileBreakpoint}) {
    & .tooltip {
      font-size: 0.5rem;
      letter-spacing: 0.009375rem;
      width: 4.8125rem;
      height: 1.7rem;
    }
  }
`;

const TooltipLocator = styled.div`
  height: 10px;
  width: 10px;
  background: transparent;
  position: absolute;
  left: ${(props) => props.tooltipLocation.left};
  top: ${(props) => props.tooltipLocation.top};
  @media (max-width: ${mobileBreakpoint}) {
    left: ${(props) => props.tooltipLocationMobile.left};
    top: ${(props) => props.tooltipLocationMobile.top};
  }
`;

// Mobile specific
const MobileTextFieldContainer = styled.div`
  ${mobileWidth};
  @media (min-width: ${mobileBreakpoint}) {
    display: none;
  }
`;

const MobileTextField = styled(MaterialUiTextField)`
  width: 100%;
  .MuiFormHelperText-contained {
    bottom: 0.4rem;
    right: -0.8rem;
    position: absolute;
    font-size: 0.625rem;
    font-weight: 500;
    border-radius: 0.25rem;
    padding: 0 .4rem;
    background: var(--color-background-faded);
  }
  .MuiInputBase-root {
    background: var(--color-white);
    border-radius: 0.25rem;
    :hover {
      background-color: var(--color-white);
    }
    textarea {
      border-radius: 0.25rem;
      background-color: var(--color-white);
      padding-right: 2.8rem;
    }
  }
  .MuiInputBase-root.Mui-focused {
    background-color: var(--color-white);
  }
  .MuiInputLabel-formControl {
    color: var(--color-card-length-message);
    &.Mui-focused {
      color: var(--color-card-length-message);
    }
  }
  @media (max-width: ${mobileBreakpoint}) {
    display: none;
  }
`;

const HelperText = styled.span`
  color: ${(props) => props.errorState ?
    'var(--color-card-error-dark)' : 'var(--color-card-length-message)'};
`;

const CaptchaLegal = styled.div`
  font-size: 0.5rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  padding: 1.25rem;
  text-align: center;
  a {
    color: var(--color-white);
  }
  @media (min-width: ${mobileBreakpoint}) {
    display: none;
  }
`;

/**
 * Creates an object containing all of the sticker names set to null.
 * @return {object}
 */
const stickerNamesRefObject = () => {
  return stickerNames.reduce((accumulator, stickerName) => {
    return {
      ...accumulator,
      [stickerName]: null,
    };
  }, {});
};
