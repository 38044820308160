import React from 'react';

import {PageContainer} from './components/PageContainer';

/**
 * Root entry point for Application
 * @return {component} The app to be rendered to the DOM.
*/
function App() {
  return (
    <PageContainer id="root" />
  );
}

export default App;
