import {useState, useCallback} from 'react';

/**
 * Get the bounding client rect of the passed in ref's DOM node.
 * Based on:
 * https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
 * Added ability to force update.
 *
 * @param {boolean} forceUpdate Forces the function to be reran.
 * @return {!Array<object>} The object rect and the ref.
 */
export const useClientRect = (forceUpdate) => {
  const [rect, setRect] = useState(null);
  const ref = useCallback((node) => {
    if (node !== null) {
      const nodeRect = node.getBoundingClientRect();
      setRect(nodeRect);
    }
  }, [forceUpdate]); // eslint-disable-line react-hooks/exhaustive-deps
  return [rect, ref];
};
