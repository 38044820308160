import {toBlob} from 'html-to-image';
import {actions, steps} from '../appReducer';

/**
 * Submit handler for Card.
 *
 * This creates the image of the Card, and submits the
 * image along with the stickers locations and the
 * messages entered by the user back to the API.
 *
 * @param {{ dispatch: function,
 *     stickerLocationRef: object,
 *     setPrintStickersToDOM: function,
 *     iLoveThatMessage: string,
 *     iWishThatMessage: string,
 *     setLoading: function
 *   }} props
 *     dispatch: dispatches a useReducer action.
 *     stickerLocationRef: The X/Y coordinates of each sticker on the Card.
 *     setPrintStickersToDOM: Prints the stickers to the DOM for screenshot.
 *     iLoveThatMessage: message entered by user.
 *     iWishThatMessage: message entered by user.
 *     setLoading: loading is true until API response, then set to false.
 * @return {undefined}
 */
export const submitCard = ({
  dispatch,
  stickerLocations,
  setPrintStickersToDOM,
  iLoveThatMessage,
  iWishThatMessage,
  setLoading,
  captchaToken,
}) => {
  const node = document.getElementById('card');
  const devicePixelRatio = window.devicePixelRatio;
  // toBlob() uses window.devicePixelRatio internally
  // so we can hijack that for a second to make all images
  // come out the same size across all devices
  window.devicePixelRatio = 1;
  toBlob(node)
      .then((blob) => {
        const localImageUrl = URL.createObjectURL(blob);
        window.devicePixelRatio = devicePixelRatio;
        const form = new FormData();
        form.append('messageILoveThat', iLoveThatMessage);
        form.append('messageIWishThat', iWishThatMessage);
        form.append('stickerLocations', JSON.stringify(stickerLocations));
        form.append('cardImage', blob);
        form.append('captchatoken', captchaToken);
        fetch(`${process.env.REACT_APP_API_URL}api/card/submit`, {
          method: 'POST',
          body: form,
        }).then((response) => {
          setLoading(false);
          if (response.status !== 201) {
            throw new Error(response.message);
          } else {
            dispatch({
              type: actions.CHANGE_STEP,
              payload: {
                step: steps.SHARE,
                localImage: localImageUrl,
              },
            });
            return response.text();
          }
        }).then((data) => {
          const response = JSON.parse(data);
          dispatch({
            type: actions.SET_PUBLIC_URL,
            payload: {
              cardPagePublicUrl: response.card_public_html_url,
              cardImagePublicUrl: response.card_image_public_url,
            },
          });
        }).catch((error) => {
          handleError({error, dispatch, setLoading, setPrintStickersToDOM});
        });
      })
      .catch((error) => {
        handleError({error, dispatch, setLoading, setPrintStickersToDOM});
      });
  return false;
};

const handleError = ({error, dispatch, setLoading, setPrintStickersToDOM}) => {
  setLoading(false);
  setPrintStickersToDOM(false);
  dispatch({
    type: actions.SHOW_ERROR,
    payload: true,
  });
};
