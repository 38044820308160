import React from 'react';
import PropTypes from 'prop-types';
import 'styled-components/macro';
import {css} from 'styled-components';
import {ImgStatic} from './Sticker';
import {stickerNames} from './constants';

import Sparky from '../../images/stickers/sparky.png';
import Please from '../../images/stickers/please.png';
import Fire from '../../images/stickers/fire.png';
import Rocket from '../../images/stickers/rocket.png';
import Heart from '../../images/stickers/heart.png';
import Happy from '../../images/stickers/happy.png';
import Sob from '../../images/stickers/sob.png';
import Clap from '../../images/stickers/clap.png';
import Ladybug from '../../images/stickers/ladybug.png';
import Coffee from '../../images/stickers/coffee.png';
import PineappleSparky from '../../images/stickers/pineapple.png';
import Games from '../../images/stickers/games.png';
import Check from '../../images/stickers/check.png';
import Performance from '../../images/stickers/performance.png';
import Bug from '../../images/stickers/bug.png';

/**
 * Writes the sticker object to the DOM on of the hidden card.
 *
 * This components 'prints' the sticker to the hidden Card
 * on the screen before the screenshot of it is taken and submitted
 * to the server.
 *
 * @param {{ cardRect: object,
 *     stickerLocationRef: object,
 *     onMobile: boolean,
 *   }} props
 *     cardRect: the dimensions and X/Y coordinates of the Card.
 *     stickerLocationRef: The X/Y coordinates of each sticker on the Card.
 *     onMobile: If the user is on a mobile screen size or not.
 * @return {component}
 */
export const PrintStickersToDOM = ({
  stickerLocationRef,
  onMobile,
  cardRect,
}) => {
  if (stickerLocationRef && !stickerLocationRef.current) {
    return null;
  }

  const stickersOnCard = stickerNames.filter((name) => {
    if (stickerLocationRef.current[name]) {
      return name;
    }
    return null;
  });

  if (stickersOnCard.length === 0) {
    return null;
  }

  // There is a bug where, if a user 'flicks' a sticker
  // onto the card, because of the inertia, the location
  // being set in useDrag isn't always 100% accurate.
  // Instead of rewriting the logic in Sticker, re-grab
  // the sticker locations here so we can be sure the
  // final locations are accurate.
  const upperBound = cardRect ? cardRect.top : 0;
  const leftBound = cardRect ? cardRect.left : 0;

  stickersOnCard.forEach((stickerName) => {
    const sticker = document.querySelector(
        `img[alt="${stickerName} sticker"]`);
    if (sticker) {
      const stickerRelativeY =
        sticker.getBoundingClientRect().top - upperBound;
      const stickerRelativeX =
        sticker.getBoundingClientRect().left - leftBound;

      stickerLocationRef.current[stickerName].y = stickerRelativeY;
      stickerLocationRef.current[stickerName].x = stickerRelativeX;
    } else {
      // This should never happen, but just incase
      stickerLocationRef.current[stickerName].y = 0;
      stickerLocationRef.current[stickerName].x = 0;
    }
  });

  return (
    <>
      {stickersOnCard.map((name) => {
        const src = getStickerUrl({name});
        // For mobile we need to scale the sticker
        // positions proportionally to the smaller card size
        const scaleY = onMobile ? 3.54 : 1;
        const scaleX = onMobile ? 3.54 : 1;
        return (
          <ImgStatic
            key={name}
            src={src}
            css={
              css`
                height: 10rem;
                width: 10rem;
                position: absolute;
                top: ${stickerLocationRef.current[name].y * scaleY + 'px'};
                left: ${stickerLocationRef.current[name].x * scaleX + 'px'};
              `
            }
          />
        );
      })}
    </>
  );
};

PrintStickersToDOM.propTypes ={
  cardRect: PropTypes.object,
  stickerLocationRef: PropTypes.object.isRequired,
  onMobile: PropTypes.bool.isRequired,
};

const getStickerUrl = ({name}) => {
  switch (name) {
    case stickerNames[0]:
      return Sparky;
    case stickerNames[1]:
      return Please;
    case stickerNames[2]:
      return Fire;
    case stickerNames[3]:
      return Rocket;
    case stickerNames[4]:
      return Heart;
    case stickerNames[5]:
      return Happy;
    case stickerNames[6]:
      return Sob;
    case stickerNames[7]:
      return Clap;
    case stickerNames[8]:
      return Ladybug;
    case stickerNames[9]:
      return Coffee;
    case stickerNames[10]:
      return PineappleSparky;
    case stickerNames[11]:
      return Games;
    case stickerNames[12]:
      return Check;
    case stickerNames[13]:
      return Performance;
    case stickerNames[14]:
      return Bug;
    default:
      return null;
  }
};
