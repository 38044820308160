import React, {useState} from 'react';
import PropTypes from 'prop-types';
import 'styled-components/macro';
import styled from 'styled-components';
import {useGesture} from 'react-use-gesture';
import {useSpring, animated, config} from 'react-spring';
import {mobileBreakpoint} from '../../helpers/breakpoints';
import {AnimatedSticker} from './AnimatedSticker';

/**
 * An individual Sticker.
 *
 * The Sticker the user can drag and drop onto the card.
 * If the Sticker is placed outside of the card area, it returns
 * to its original location on the Sidebar.
 *
 * @param {{ cardRect: object,
 *     isDraggingSticker: string,
 *     setIsDraggingSticker: function,
 *     emojiStatic: string,
 *     emojiAnimated: string,
 *     stickerLocationRef: object,
 *     stickerName: string,
 *     showCardTooltip: boolean,
 *     setShowCardTooltip: function,
 *   }} props
 *     cardRect: the dimensions and X/Y coordinates of the Card.
 *     isDraggingSticker: the name of the sticker currently being dragged.
 *     setIsDraggingSticker: set name of the sticker currently being dragged.
 *     emojiStatic: URL of static sticker image.
 *     emojiAnimated: URL of animated sticker image.
 *     stickerLocationRef: The X/Y coordinates of each sticker on the Card.
 *     stickerName: the name of the sticker.
 *     showCardTooltip: If card tooltip is being displayed.
 *     setIsDraggingStickerName: Set the card tooltip display state.
 * @return {component}
 */
export const Sticker = ({
  cardRect,
  emojiStatic,
  emojiAnimated,
  stickerLocationRef,
  stickerName,
  isDraggingSticker,
  setIsDraggingSticker,
  showCardTooltip,
  setShowCardTooltip,
}) => {
  // `blockPointerEvents` serves two purposes:
  // 1. Don't allow user to interact with stickers until `onRest` is
  // called above, which means this sticker has returned 'home'.
  // This avoids edge cases that cause strange behaviour,
  // like someone trying to grab the sticker before its returned.
  //
  // 2. This also acts as a workaround for this browser bug,
  // which causes hover location to get out of sync with sticker:
  // https://bugs.chromium.org/p/chromium/issues/detail?id=410328#c5
  const [blockPointerEvents, setBlockPointerEvents] = useState(false);
  const [scaled, setScaled] = useState(false);
  const [atInitial, setAtInitial] = useState(true);
  const [hoveringSticker, setHoveringSticker] = useState(false);
  const [props, set] = useSpring(() => ({
    config: config.stiff,
    x: 0,
    y: 0,
    onRest: (state) => {
      const {value} = state;
      if (value === 0) {
        // If user drops sticker off card, once
        // it returns 'home', enable dock interactions
        setAtInitial(true);
        setBlockPointerEvents(false);
      }
    },
  }));
  const {x, y} = props;
  const upperBound = cardRect ? cardRect.top : 0;
  const lowerBound = cardRect ? cardRect.bottom : 0;
  const leftBound = cardRect ? cardRect.left : 0;
  const rightBound = cardRect ? cardRect.right : 0;
  const desktopScale = 3.33;
  const mobileScale = 0.9;
  const onMobile = matchMedia(`(max-width: ${mobileBreakpoint})`);

  // Return cards on card home if user resizes browser
  if (stickerLocationRef?.current &&
      stickerLocationRef.current[stickerName]) {
    if (props?.scale?.animation?.to) {
      if (!onMobile.matches &&
          props.scale.animation.to !== desktopScale) {
        set({scale: 1, y: 0, x: 0});
        stickerLocationRef.current[stickerName] = null;
      }

      if (onMobile.matches &&
          props.scale.animation.to !== mobileScale) {
        set({scale: 1, y: 0, x: 0});
        stickerLocationRef.current[stickerName] = null;
      }
    }
  }

  const bind = useGesture({
    onHover: (state) => {
      const {hovering} = state;
      if (!scaled && atInitial && hovering) {
        set({scale: 1.4, from: {scale: 1}});
        setScaled(true);
      }
      if (scaled && atInitial && !hovering) {
        set({scale: 1});
        setScaled(false);
      }
    },
    onDrag: (state) => {
      const {
        event: {target},
        xy: [pointerX, pointerY],
        down,
        movement: [mx, my],
        active,
      } = state;

      if (!active) {
        setIsDraggingSticker(null);
      }
      if (atInitial) {
        setAtInitial(false);
      }
      if (blockPointerEvents) {
        setBlockPointerEvents(false);
      }

      const stickerIsOnCard = pointerX > leftBound &&
        pointerX < rightBound &&
        pointerY > upperBound &&
        pointerY < lowerBound;

      if (stickerIsOnCard) {
        // Leave sticker on card.
        if (active && isDraggingSticker === null) {
          setIsDraggingSticker(stickerName);
        }
        if (onMobile.matches) {
          set({scale: mobileScale});
        } else {
          set({scale: desktopScale});
        }

        // Save the sticker locations so they can be printed
        // to the card for taking the screenshot of it
        const stickerRelativeY =
          target.getBoundingClientRect().top - upperBound;
        const stickerRelativeX =
          target.getBoundingClientRect().left - leftBound;
        stickerLocationRef.current = {
          ...stickerLocationRef.current,
          [stickerName]: {x: stickerRelativeX, y: stickerRelativeY},
        };
        set({
          x: mx,
          y: my,
        });
      } else {
        // Return sticker 'home'
        stickerLocationRef.current[stickerName] = null;
        if (!active && isDraggingSticker !== stickerName) {
          setIsDraggingSticker(null);
        }
        set({x: down ? mx : 0, y: down ? my : 0});
        if (down === false) {
          set({scale: 1});
          setScaled(false);
          setBlockPointerEvents(true);
        }
      }
    },
    onDragEnd: (state) => {
      if (state.movement[0] === 0 && state.movement[1] === 0) {
        // If user just clicks on the sticker without dragging, need
        // to make sure setAtInitial is set back to true
        setAtInitial(true);
      }
      setBlockPointerEvents(false);

      // Hide tooltip on card after one sticker is dropped
      if (showCardTooltip) {
        setShowCardTooltip(false);
      }
    },
  }, {drag: {initial: () => [x.get(), y.get()]}});

  return (
    <StickerContainer
      showOverTrash={isDraggingSticker === stickerName}
      blockPointerEvents={blockPointerEvents}
      onMouseEnter={() => setHoveringSticker(true)}
      onMouseLeave={() => setHoveringSticker(false)}
    >
      <StickerDiv {...bind()} style={props}>
        {!hoveringSticker &&
          <ImgStatic src={emojiStatic} alt={`${stickerName} sticker`} />}
        {hoveringSticker &&
          <AnimatedSticker
            name={stickerName}
            animationPath={emojiAnimated}
          />}
      </StickerDiv>
    </StickerContainer>
  );
};

Sticker.propTypes = {
  cardRect: PropTypes.object,
  isDraggingSticker: PropTypes.string,
  setIsDraggingSticker: PropTypes.func.isRequired,
  emojiStatic: PropTypes.string.isRequired,
  emojiAnimated: PropTypes.string,
  stickerLocationRef: PropTypes.object.isRequired,
  stickerName: PropTypes.string.isRequired,
  showCardTooltip: PropTypes.bool.isRequired,
  setShowCardTooltip: PropTypes.func.isRequired,
};

const StickerContainer = styled.div`
  position: relative;
  z-index: ${(props) => props.showOverTrash ? 3 : 1};
  background: ${(props) => props.showOverTrash ?
    'transparent' : 'rgba(255, 255, 255, 0.2)'};
  pointer-events: ${(props) => props.blockPointerEvents ? 'none' : 'auto'};
  border-radius: 50%;
`;

const StickerDiv = styled(animated.div)`
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  touch-action: none;
  height: 3rem;
  width: 3rem;
  user-select: none;
  border-radius: 50%;
  overflow: hidden;
`;

export const ImgStatic = styled.img`
  /* So browsers 'grab image' doesn't get triggered */
  pointer-events: none;
  height: 100%;
  width: 100%;
  // Fix for blurry image in safari due to using transform scale()
  transform: translateZ(0);
`;
