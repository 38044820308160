import React from 'react';
import ReactModal from 'react-modal';
import 'styled-components/macro';
import styled, {css} from 'styled-components';
import {actions, steps} from '../appReducer';

const customStyles = {
  content: {
    top: '49%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '999',
    height: '11.3rem',
    width: '19.4rem',
    padding: '0rem 1.4rem',
    fontSize: '1.25rem',
    borderRadius: '0.5rem',
    color: '#5F6368',
    background: '#ffffff',
  },
  overlay: {
    zIndex: '998',
    background: 'rgba(20, 61, 119, 0.8)',
  },
};

ReactModal.setAppElement('#root');


/**
 * Global Modal.
 *
 * Used to warn the user that starting over will make
 * them lose any card creation progress they have made.
 *
 * @param {{dispatch: function, state: object}} props
 *     dispatch: dispatches a useReducer action.
 *     state: applications global state.
 * @return {component}
 */
export const Modal = ({state, dispatch}) => {
  const handleCloseModal = () => {
    dispatch({
      type: actions.SHOW_MODAL,
      payload: false,
    });
  };

  const handleStartOver = () => {
    dispatch({
      type: actions.CHANGE_STEP,
      payload: steps.START,
    });
  };

  return (
    <div>
      <ReactModal
        isOpen={state.showModal}
        style={customStyles}
        contentLabel="Example Modal"
        onRequestClose={handleCloseModal}
        closeTimeoutMS={0}
      >
        <h1 css={css`
          color: #414549;
          font-size: 1rem;
          margin-top: 1.4rem;
          font-weight: 500;
        `}>
          Are you sure?
        </h1>
        <div css={css`
          font-family: 'Roboto', sans-serif;
          font-size: 0.875rem;
          font-weight: 400;
          color: #5F6368;
          margin-top: 0.8rem;
          line-height: 1.25rem;
          letter-spacing: 0.0125rem;
        `}>
          When you start over, all of your changes will be lost.
          You’ll be prompted to choose a new postcard.
        </div>
        <div css={css`
          height: 2.78rem;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
        `}>
          <ModalButton
            onClick={() => handleCloseModal()}
            css={css`margin-right: 1.2rem;`}
          >
            Cancel
          </ModalButton>
          <ModalButton
            onClick={() => handleStartOver()}
            css={css`margin-right: -0.4rem;`}
          >
            Start over
          </ModalButton>
        </div>
      </ReactModal>
    </div>
  );
};

const ModalButton = styled.button`
  cursor: pointer;
  color: #1A73E8;
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.015625rem;
  background: none;
  border: none;
  outline: none;
`;
