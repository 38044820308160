/**
 * An array of all the sticker names.
 * @type {Array<string>}
 */
export const stickerNames = [
  'sparky',
  'please',
  'fire',
  'rocket',
  'heart',
  'happy',
  'sob',
  'clap',
  'ladybug',
  'coffee',
  'pineappleSparky',
  'games',
  'check',
  'performance',
  'bug',
];
