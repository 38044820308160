import React from 'react';
import {Helmet} from 'react-helmet';

// Need to add fonts
// Need to check what font-weights we use and preload them
// we dont use italics anywhere

import GoogleSansRegular from '../fonts/GoogleSans-Regular.ttf';
import GoogleSansMedium from '../fonts/GoogleSans-Medium.ttf';
import GoogleSansBold from '../fonts/GoogleSans-Bold.ttf';
import Coda from '../fonts/Coda-Regular.ttf';
import Lacquer from '../fonts/Lacquer-Regular.ttf';
import RobotoMono from '../fonts/RobotoMono-Regular.ttf';


// Theme images
import MinimalCard from '../images/theme-minimal-card.svg';
import FourKCard from '../images/theme-4k-card.svg';
import ColorfulCard from '../images/theme-colorful-card.svg';
import TraditionalCard from '../images/theme-traditional-card.svg';
import MinimalCardNoTargets from
  '../images/theme-minimal-no-drop-targets.svg';
import FourKCardNoTargets from '../images/theme-4k-no-drop-targets.svg';
import ColorfulCardNoTargets from
  '../images/theme-colorful-no-drop-targets.svg';
import TraditionalCardNoTargets
  from '../images/theme-traditional-no-drop-targets.svg';

// Gifs
import SparkyGif from '../images/stickers/sparky.gif';
import PleaseGif from '../images/stickers/please.gif';
import FireGif from '../images/stickers/fire.gif';
import RocketGif from '../images/stickers/rocket.gif';
import HeartGif from '../images/stickers/heart.gif';
import HappyGif from '../images/stickers/happy.gif';
import SobGif from '../images/stickers/sob.gif';
import ClapGif from '../images/stickers/clap.gif';
import LadybugGif from '../images/stickers/ladybug.gif';
import CoffeeGif from '../images/stickers/coffee.gif';
import PineappleSparkyGif from '../images/stickers/pineapple.gif';
import GamesGif from '../images/stickers/games.gif';
import CheckGif from '../images/stickers/check.gif';
import PerformanceGif from '../images/stickers/performance.gif';
import BugGif from '../images/stickers/bug.gif';

/**
* Preload assets.
*
* We need to preload animated image gifs so there isn't an
* initial flicker or any load time on hover. We also want to preload fonts, and
* theme background images as they are used on the next page.
* @return {component}
*/
export const PreloadImages = () => {
  // react-helmet is the cause of the UNSAFE_componentWillMount warning
  // https://github.com/nfl/react-helmet/issues/548
  return (
    <Helmet>
      <link
        rel="preload"
        href={GoogleSansRegular}
        as="font"
        crossorigin="anonymous"
      />
      <link rel="preload"
        href={GoogleSansMedium}
        as="font"
        crossorigin="anonymous"
      />
      <link
        rel="preload"
        href={GoogleSansBold}
        as="font"
        crossorigin="anonymous"
      />
      <link rel="preload" href={Coda} as="font" crossorigin="anonymous" />
      <link rel="preload" href={Lacquer} as="font" crossorigin="anonymous" />
      <link rel="preload" href={RobotoMono} as="font" crossorigin="anonymous" />
      {/* This is totally abusing preload and I idk how I feel about it.
          Might not be the way to go.
          Could instead preload images in a hidden div?
      */}
      <link rel="preload" href={MinimalCard} as="image" type="image/svg+xml" />
      <link rel="preload" href={FourKCard} as="image" type="image/svg+xml" />
      <link rel="preload"
        href={ColorfulCard}
        as="image"
        type="image/svg+xml"
      />
      <link
        rel="preload"
        href={TraditionalCard}
        as="image"
        type="image/svg+xml"
      />
      <link rel="preload"
        href={MinimalCardNoTargets}
        as="image"
        type="image/svg+xml"
      />
      <link
        rel="preload"
        href={FourKCardNoTargets}
        as="image"
        type="image/svg+xml"
      />
      <link rel="preload"
        href={ColorfulCardNoTargets}
        as="image"
        type="image/svg+xml"
      />
      <link
        rel="preload"
        href={TraditionalCardNoTargets}
        as="image"
        type="image/svg+xml"
      />
      <link rel="preload" href={SparkyGif} as="image" type="image/gif" />
      <link rel="preload" href={PleaseGif} as="image" type="image/gif" />
      <link rel="preload" href={FireGif} as="image" type="image/gif" />
      <link rel="preload" href={RocketGif} as="image" type="image/gif" />
      <link rel="preload" href={HeartGif} as="image" type="image/gif" />
      <link rel="preload" href={HappyGif} as="image" type="image/gif" />
      <link rel="preload" href={SobGif} as="image" type="image/gif" />
      <link rel="preload" href={ClapGif} as="image" type="image/gif" />
      <link rel="preload" href={LadybugGif} as="image" type="image/gif" />
      <link rel="preload" href={CoffeeGif} as="image" type="image/gif" />
      <link
        rel="preload"
        href={PineappleSparkyGif}
        as="image"
        type="image/gif"
      />
      <link rel="preload" href={GamesGif} as="image" type="image/gif" />
      <link rel="preload" href={CheckGif} as="image" type="image/gif" />
      <link rel="preload" href={PerformanceGif} as="image" type="image/gif" />
      <link rel="preload" href={BugGif} as="image" type="image/gif" />
    </Helmet>
  );
};
