import React from 'react';
import {Header} from './Header';
import PropTypes from 'prop-types';
import 'styled-components/macro';
import styled, {css} from 'styled-components';
import {
  mobileBreakpoint,
  displayNoneMobile,
} from '../helpers/breakpoints';
import {actions, themes, steps} from './appReducer';
import {Button, ButtonMobileContainer, ButtonGoBack} from './UI/Button';

import MinimalCard from '../images/theme-minimal-card.svg';
import FourKCard from '../images/theme-4k-card.svg';
import ColorfulCard from '../images/theme-colorful-card.svg';
import TraditionalCard from '../images/theme-traditional-card.svg';

const thumbnailWidth = '14.25rem';
const largerBreakpoint = '70.375rem';

/**
 * The Theme Selection Page.
 *
 * Allows user to select a theme to use for the
 * postcard creation.
 *
 * @param {{dispatch: function, state: object}} props
 *     dispatch: dispatches a useReducer action.
 *     state: applications global state.
 * @return {component}
 */
export const ChooseThemePage = ({dispatch, state}) => {
  const handleSelectTheme = (e, theme) => {
    dispatch({
      type: actions.SELECT_THEME,
      payload: theme,
    });
  };

  const handleShowModal = (e) => {
    e.preventDefault();
    dispatch({
      type: actions.SHOW_MODAL,
      payload: true,
    });
  };

  const handleNext = (e) => {
    e.preventDefault();
    dispatch({
      type: actions.CHANGE_STEP,
      payload: steps.CARD,
    });
  };

  return (
    <>
      <Header dispatch={dispatch} />
      <form css={css`
        margin-top: 6.4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: ${largerBreakpoint}) {
          margin-top: 4.4rem;
        }
        @media (max-width: ${mobileBreakpoint}) {
          margin-top: 1rem;
          min-height: 34rem;
        }
      `}>
        <fieldset css={`
          text-align: center;
          border: 0;
          display: flex;
          width: 100%;
          @media (max-width: ${largerBreakpoint}) {
            width: auto;
          }
          @media (max-width: ${mobileBreakpoint}) {
            width: 20.6rem;
          }
        `}>
          <legend css={css`
            font-size: 2.25rem;
            font-weight: 500;
            @media (max-width: ${mobileBreakpoint}) {
              font-size: 1.125rem;
            }
          `}>
            Choose your postcard
          </legend>
          <div css={css`
            margin-top: 4.8rem;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            width: 100%;
            @media (max-width: ${largerBreakpoint}) {
              padding: 0 3rem;
              width: 40rem;
            }
            @media (max-width: ${mobileBreakpoint}) {
              padding: 0;
              margin-top: 2rem;
              max-width: 40rem;
              width: auto;
              display: flex;
              justify-content: space-between;
            }
          `}>
            <Label selected={state.theme === themes.MINIMAL}>
              <Thumbnail
                src={MinimalCard}
                alt="Minimal Theme"
              />
              <ThemeName>Arcs</ThemeName>
              <Input
                type="radio"
                name="minimal-theme"
                checked={state.theme === themes.MINIMAL}
                onChange={(e) => handleSelectTheme(e, themes.MINIMAL)}
              />
            </Label>
            <Label selected={state.theme === themes.FOUR_K}>
              <Thumbnail
                src={FourKCard}
                alt="4K Theme"
              />
              <ThemeName>Browser</ThemeName>
              <Input
                type="radio"
                name="fourk-theme"
                checked={state.theme === themes.FOUR_K}
                onChange={(e) => handleSelectTheme(e, themes.FOUR_K)}
              />
            </Label>
            <Label selected={state.theme === themes.COLORFUL}>
              <Thumbnail
                src={ColorfulCard}
                alt="Colorful Theme"
              />
              <ThemeName>Color Pop</ThemeName>
              <Input
                type="radio"
                name="colorful-theme"
                checked={state.theme === themes.COLORFUL}
                onChange={(e) => handleSelectTheme(e, themes.COLORFUL)}
              />
            </Label>
            <Label selected={state.theme === themes.TRADITIONAL}>
              <Thumbnail
                src={TraditionalCard}
                alt="Traditional Theme"
              />
              <ThemeName>Classic</ThemeName>
              <Input
                type="radio"
                name="traditional-theme"
                checked={state.theme === themes.TRADITIONAL}
                onChange={(e) => handleSelectTheme(e, themes.TRADITIONAL)}
              />
            </Label>
          </div>
        </fieldset>
        <Button
          css={css`
            margin-top: 4.4rem;
            ${displayNoneMobile};
            @media (max-width: ${largerBreakpoint}) {
              margin-bottom: 3rem;
            }
          `}
          width="5.9375rem"
          disabled={state.theme === null}
          onClick={handleNext}
        >
          Next
        </Button>
        <ButtonMobileContainer>
          <ButtonGoBack onClick={handleShowModal}>&nbsp;</ButtonGoBack>
          <Button onClick={handleNext} disabled={state.theme === null}>
            Next
          </Button>
        </ButtonMobileContainer>
      </form>
    </>
  );
};

ChooseThemePage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
};

const ThemeName = styled.span`
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 700;
  margin: 1.6rem 0;
  @media (max-width: ${mobileBreakpoint}) {
    font-size: 0.875rem;
    margin: .7rem 0 0 0;
  }
`;

const Thumbnail = styled.img`
  width: ${thumbnailWidth};
  border-radius: .25rem;
`;

const Label = styled.label`
  cursor: pointer;
  background: ${(props) => props.selected ?
    'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.08)'};
  width: 16.25rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 1.35rem;
  padding-bottom: 1.33rem;
  border-radius: 0.5rem;
  transition: var(--transition-all);
  border: ${(props) => props.selected ?
    '0.125rem solid var(--color-white)' :
    '0.125rem solid transparent'};
  &:hover {
    background: ${(props) => props.selected ?
    'rgba(0, 0, 0, 0.16)' : 'rgba(255, 255, 255, 0.12)'};
  }
  &:focus-within {
    outline: 0;
    border: 2px solid #FFFFFF;
  }
  @media (max-width: ${largerBreakpoint}) {
    margin-bottom: .7rem;
  }
  @media (max-width: ${mobileBreakpoint}) {
    width: 9rem;
    padding-top: 1rem;
    padding-bottom: 0.8rem;
    margin-bottom: 1rem;
    margin-top: 0.2rem;
  }
  img {
    cursor: pointer;
    @media (max-width: ${mobileBreakpoint}) {
      width: 7.5rem;
    }
  }
`;

const Input = styled.input`
  cursor: pointer;
  margin-top: 1rem;
  appearance: none;
  border-radius: 50%;
  border: 0.125rem solid var(--color-white);
  width: 1rem;
  height: 1rem;
  outline: 0;
  &:checked {
    margin-top: 1.1rem;
    border: 0.18rem solid #165ab8;
    box-shadow: 0 0 0 0.115rem var(--color-btn-primary-default);
    background: var(--color-btn-primary-default);
    height: 0.8rem;
    width: 0.8rem;
  }
`;
