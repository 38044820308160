import React from 'react';
import PropTypes from 'prop-types';
import 'styled-components/macro';
import styled, {css} from 'styled-components';
import FirebaseVerticalLockup
  from '../images/dear-firebase-vertical-lockup.svg';
import {mobileBreakpoint, displayNoneMobile} from '../helpers/breakpoints';
import {actions, steps} from './appReducer';
import {ButtonXL, Button, ButtonMobileContainer} from './UI/Button';

const displayBlockOnDesktopBreakpoint = '66rem';

/**
 * The Start Page.
 *
 * The first page the user sees, and is returned on
 * if they choose to restart.
 *
 * @param {{dispatch: function}} props
 *     dispatch: dispatches a useReducer action.
 * @return {component}
 */
export const StartPage = ({dispatch}) => {
  const handleNext = (e) => {
    e.preventDefault();
    dispatch({
      type: actions.CHANGE_STEP,
      payload: steps.THEME,
    });
  };

  return (
    <>
      <div css={css`
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        margin-top: 5.4rem;
        z-index: 99;
        @media (max-width: ${mobileBreakpoint}) {
          margin-top: 10vh;
        }
      `}>
        <img
          src={FirebaseVerticalLockup}
          css={css`
            height: 160px;
            @media (max-width: ${mobileBreakpoint}) {
              height: 120px;
            }
          `}
          alt="Firebase"
        />
        <div css={css`
          margin-top: 6.9rem;
          font-size: 2.25rem;
          font-weight: 500;
          max-width: 66rem;
          text-align: center;
          line-height: 3.15rem;
          @media (max-width: ${displayBlockOnDesktopBreakpoint}) {
            padding: 0 1.875rem;
          }
          @media (max-width: ${mobileBreakpoint}) {
            line-height: 1.5rem;
            font-size: 1.125rem;
            margin-top: 3.25rem;
            min-height: 18rem;
          }
        `}>
          <DisplayBlockOnDesktop>
            Write us a note to say hello, tell us what you love, and
          </DisplayBlockOnDesktop>
          <DisplayBlockOnDesktop>
            what could be better, about Firebase. Remember to keep
          </DisplayBlockOnDesktop>
          <DisplayBlockOnDesktop>
            your responses anonymous and don't forget to decorate
          </DisplayBlockOnDesktop>
          <DisplayBlockOnDesktop>
            your postcard with our fun stickers! We can't wait to
          </DisplayBlockOnDesktop>
          <DisplayBlockOnDesktop>
          read your letters.
          </DisplayBlockOnDesktop>
        </div>

        <ButtonXL
          onClick={handleNext}
          css={css`${displayNoneMobile};`}
        >
          Start your note
        </ButtonXL>
        <ButtonMobileContainer>
          <Button onClick={handleNext}>
            Start your note
          </Button>
        </ButtonMobileContainer>
      </div>
    </>
  );
};

StartPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const DisplayBlockOnDesktop = styled.span`
  display: block;
  @media (max-width: ${displayBlockOnDesktopBreakpoint}) {
    display: inline;
    &:after {
      content:"\\00a0";
    }
  }
`;
