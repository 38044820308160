/**
 * Gets the page scrollTop value.
 * @return {number} The number of px the page is scrolled.
 */
export const getScrollTop = () => {
  return (window.pageYOffset !== undefined) ?
    window.pageYOffset :
    (document.documentElement ||
      document.body.parentNode ||
      document.body
    ).scrollTop;
};
