import React from 'react';
import PropTypes from 'prop-types';
import 'styled-components/macro';
import styled, {css} from 'styled-components';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import GetAppIcon from '@material-ui/icons/GetApp';
import HomeIcon from '@material-ui/icons/Home';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

import {actions, steps} from './appReducer';
import {Header} from './Header';
import {buttonCSS, buttonXLCSS, buttonIconCSS} from './UI/Button';
import {mobileBreakpoint} from '../helpers/breakpoints';
import TopLeftCard from '../images/share/top-left-card.png';
import BottomLeftCard from '../images/share/bottom-left-card.png';
import TopRightCard from '../images/share/top-right-card.png';
import BottomRightCard from '../images/share/bottom-right-card.png';


/**
 * Share postcard page.
 *
 * Allows user to see an image of the postcard they created.
 * From this page the user can also download the image,
 * share it to social media (twitter / facebook / linkedin),
 * or return back to the Start Page to create another postcard.
 *
 * @param {{dispatch: function, state: object}} props
 *     dispatch: dispatches a useReducer action.
 *     state: applications global state.
 * @return {component}
 */
export const SharePage = ({dispatch, state}) => {
  const {cardImagePublicUrl, cardPagePublicUrl, localImage} = state;
  const handleStartover = () => {
    dispatch({
      type: actions.CHANGE_STEP,
      payload: steps.START,
    });
  };

  return (
    <>
      <div>
        <Header dispatch={dispatch} state={state} />
        <ShareContainer>
          <Title>Postcard sent!</Title>
          <CardImage
            src={cardImagePublicUrl}
            alt="Dear Firebase Postcard"
          />

          <ShareButtons>
            <TwitterShareButton
              title="Check out my postcard!"
              hashtags={['dearfirebase']}
              url={cardPagePublicUrl}
              css={css`
                margin-top: 3.1rem;
                @media (max-width: ${mobileBreakpoint}) {
                  width: 100%;
                  margin-top: 1.4rem;
                }
              `}
            >
              <TwitterShare>
                <TwitterIcon />
                <div css={css`margin-left: 0.4375rem;`}>
                  Share #dearfirebase
                </div>
              </TwitterShare>
            </TwitterShareButton>
            <div css={css`
              display: flex;
              justify-content: space-between;
              width: 14.875rem;
              margin-top: 1.5rem;
            `}>
              <FacebookShareButton
                quote="Check out my postcard!"
                hashtag="#dearfirebase"
                url={cardImagePublicUrl}
              >
                <SocialIcon>
                  <FacebookIcon fontSize={'inherit'} />
                </SocialIcon>
              </FacebookShareButton>
              <LinkedinShareButton
                title="Dear Firebase"
                summary=""
                source=""
                url={cardPagePublicUrl}
              >
                <SocialIcon>
                  <LinkedInIcon fontSize={'inherit'} />
                </SocialIcon>
              </LinkedinShareButton>
              <a href={localImage}
                download="DearFirebase-Postcard.png"
              >
                <SocialIcon>
                  <GetAppIcon fontSize={'inherit'} />
                </SocialIcon>
              </a>
              <SocialIcon onClick={handleStartover}>
                <HomeIcon fontSize={'inherit'} />
              </SocialIcon>
            </div>
          </ShareButtons>
        </ShareContainer>
      </div>
      <CardBgImage src={TopLeftCard} top={'227px'} left={8} />
      <CardBgImage src={BottomLeftCard} top={'493px'} left={-10} />
      <CardBgImage src={TopRightCard} top={'181px'} right={8} />
      <CardBgImage src={BottomRightCard} top={'473px'} right={-6} />
    </>
  );
};

SharePage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
};

const ShareContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: ${mobileBreakpoint}) {
    padding-bottom: 10rem;
  }
`;

const Title = styled.h1`
  font-size: 2.25rem;
  font-weight: 500;
  margin-top: 3.1rem;
  margin-bottom: 5.1rem;
  @media (max-width: ${mobileBreakpoint}) {
    margin-top: 15vh;
    margin-bottom: 1.8rem;
    font-size: 1.125rem;
  }
`;

const TwitterShare = styled.div`
  ${buttonCSS};
  ${buttonXLCSS};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20.5rem;
  margin: 0;
  @media (max-width: ${mobileBreakpoint}) {
    width: 100%;
    margin: 0;
    height: 2.625rem;
    font-size: 0.875rem;
  }
`;
const ShareButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${mobileBreakpoint}) {
    background: var(--color-main-background);
    box-shadow: 0 0 14px 4px var(--color-main-background);
    flex-direction: column-reverse;
    position: fixed;
    padding: 1rem;
    bottom: 0rem;
    left: 0rem;
    right: 0rem;
    z-index: 1000;
  }
`;
const SocialIcon = styled.span`
  ${buttonIconCSS};
  font-size: 1.6rem;
`;

const CardImage = styled.img`
  width: 100%;
  max-width: 45.125rem;
  height: auto;
  z-index: 99;
  @media (max-width: ${mobileBreakpoint}) {
    width: 90%;
    font-size: 1.125rem;
  }
`;

const CardBgImage = styled.img`
  position: absolute;
  top: ${(props) => props.top ? props.top : 'auto'};
  left: ${(props) => props.left ? `${props.left /3 }%` : 'auto'};
  right: ${(props) => props.right ? `${props.right / 3}%` : 'auto'};
  @media (max-width: 1550px) {
    left: ${(props) => props.left ? `${props.left / 2}%` : 'auto'};
    right: ${(props) => props.right ? `${props.right / 2}%` : 'auto'};
  }
  @media (max-width: 1400px) {
    left: ${(props) => props.left ? `${props.left / 4}%` : 'auto'};
    right: ${(props) => props.right ? `${props.right / 4}%` : 'auto'};
  }
  @media (max-width: 1240px) {
    left: ${(props) => props.left ? `${Math.abs(props.left) / 8}%` : 'auto'};
    right: ${(props) => props.right ? `${Math.abs(props.right) / 8}%` : 'auto'};
  }
  @media (max-width: 1136px) {
    left: ${(props) => props.left ? `${Math.abs(props.left) / 18}%` : 'auto'};
    right: ${(props) => props.right ?
      `${Math.abs(props.right) / 18}%` : 'auto'};
  }
  @media (max-width: 1112px) {
    display: none;
  }
`;
